import React,{useEffect} from "react";
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200
});
export default function Services() {
  const phoneNumber = "+91 9977323535";
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1> Services</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in"> Services</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="about-two hire mx-2" >
        <div class="outer-container clearfix" data-aos="zoom-in">
          <div class="image-column">
            <div class="image-layer"></div>
          </div>

          <div class="content-column">
            <div class="clearfix">
              <div class="inner" data-aos="fade-right">
                <div class="sec-title-two" >
                  <h1>Who We Are</h1>
                </div>

                <h1>India’s </h1>
                <h1> SEBI Registered Research Analyst</h1>

                <div class="text" data-aos="fade-right">
                  <p>
                    Assure Research Analyst stands as a premier SEBI Registered
                    Research Analyst (SEBI Registration Number – INH000011802)
                    in India, spearheading the next generation of equity
                    research analysis and innovative research recommendation
                    services. Our commitment to excellence is underscored by
                    unparalleled features and distinctive offerings. With a
                    remarkable track record of client retention, we set a
                    benchmark in the industry.
                  </p>
                </div>
                <div class="link-box" data-aos="fade-right">
                  <Link
                    to={`tel:${phoneNumber}`}
                    className="theme-btn btn-style-two"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*News Section*/}
      <section className="news-section-two">
          <div className="auto-container">
            {/*Heading*/}
            <div className="sec-title-two centered" data-aos="zoom-in">
              <h1>OUR SERVICES</h1>
              <div class="clearfix" data-aos="zoom-in">
                <h3>
                  We proudly stand as India’s premier Nifty and Bank Nifty
                  Option Tips Specialist, heading a distinguished Research
                  Analyst firm. Our foundation rests upon the trust of our
                  valued customers and the precision of our recommendations,
                  setting us apart from the rest.
                </h3>
              </div>
            </div>

            <div className="row clearfix gap-3">
              {/*News Block*/}
              <div className="news-block-two col-md-6  col-sm-6 col-xs-12" data-aos="fade-right">
                <div className="inner-box">
                  <figure className="image-box">
                    <Link to="/">
                      <img src="assets/images/resource/image-13.jpg" alt="" />
                    </Link>
                  </figure>
                  <div className="lower-box" >
                    {/* <div className="post-info">Post: 15 April 2018</div> */}
                    <h3 data-aos="zoom-in">
                      <Link to="/">Stock Market Intraday</Link>
                    </h3>
                    <div className="text" data-aos="zoom-in">
                      Our Day Trading calls are meticulously crafted
                      recommendations, derived from a comprehensive analysis of
                      technical and fundamental data.
                    </div>
                    <Link
                      to={`tel:${phoneNumber}`}
                      class="theme-btn btn-style-four"
                      data-aos="zoom-in"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
              {/*News Block*/}
              <div className="news-block-two col-md-6  col-sm-6 col-xs-12" data-aos="fade-left">
                <div className="inner-box">
                  <figure className="image-box">
                    <Link to="/">
                      <img src="assets/images/resource/image-14.jpg" alt="" />
                    </Link>
                  </figure>
                  <div className="lower-box">
                    <h3>
                      <Link to="/" data-aos="zoom-in">
                        Bank Nifty Futures & Options
                      </Link>
                    </h3>
                    <div className="text" data-aos="zoom-in">
                      Leading the charts in advisory services for Bank Nifty
                      futures and options. Trade with minimal capital and unlock
                      substantial returns from the options market.
                    </div>
                    <Link
                      to={`tel:${phoneNumber}`}
                      class="theme-btn btn-style-four"
                      data-aos="zoom-in"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
              {/*News Block*/}
              <div className="news-block-two col-md-6  col-sm-6 col-xs-12" data-aos="fade-right">
                <div className="inner-box">
                  <figure className="image-box">
                    <Link to="/">
                      <img src="assets/images/resource/image-3.jpg" alt="" />
                    </Link>
                  </figure>
                  <div className="lower-box">
                    {/* <div className="post-info">Post: 19 April 2018</div> */}
                    <h3>
                      <Link to="/" data-aos="zoom-in">Stock Futures & Options</Link>
                    </h3>
                    <div className="text" data-aos="zoom-in">
                      Empower your investments with accurate F&O tips. Futures
                      and options present a compelling avenue to amplify your
                      wealth effortlessly.
                    </div>
                    <Link
                      to={`tel:${phoneNumber}`}
                      class="theme-btn btn-style-four"
                      data-aos="zoom-in"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="news-block-two col-md-6  col-sm-6 col-xs-12" data-aos="fade-left">
                <div className="inner-box">
                  <figure className="image-box">
                    <Link to="/">
                      <img src="assets/images/resource/image-2.jpg" alt="" />
                    </Link>
                  </figure>
                  <div className="lower-box">
                    {/* <div className="post-info">Post: 19 April 2018</div> */}
                    <h3>
                      <Link to="/" data-aos="zoom-in">Nifty Futures & Options</Link>
                    </h3>
                    <div className="text" data-aos="zoom-in">
                      Our future and option tips exhibit exceptional accuracy.
                      Nifty trading emerges as an ideal choice for attaining
                      short-term gains.
                    </div>
                    <Link
                    data-aos="zoom-in"
                      to={`tel:${phoneNumber}`}
                      class="theme-btn btn-style-four"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      {/* offer */}
      <section className="news-section-two">
        <div className="auto-container">
          {/*Heading*/}
          <div className="sec-title-two centered">
            <h1  data-aos="zoom-out">Our Packages</h1>
            <div class="clearfix" >
              <h2 data-aos="zoom-out">
                We provide reliable & accurate calls in stock market. Our
                professional stock experts offer best tips Service for Indian
                Stock Market.
              </h2>
            </div>
          </div>

          <div className="row clearfix">
            {/*News Block*/}
            <div className="news-block-two col-md-4 col-sm-6 col-xs-12" data-aos="fade-right">
              <div className="inner-box  services-card ">
                <div className="lower-box">
                  {/* <div className="post-info">Post: 15 April 2018</div> */}
                  <h3>
                    <Link to="/payment">Stock Market Intraday</Link>
                  </h3>
                  <div className="text">
                    Our Day Trading calls are meticulously crafted
                    recommendations, derived from a comprehensive analysis of
                    technical and fundamental data.
                  </div>
                  <div className="text">
                    1-2 Intraday Cash/Equity trading tips with high accuracy.
                  </div>
                  <div className="text">
                    Clear enter level, Target level premarket out book by SMS
                    and Whatsapp.
                  </div>
                  <div className="text">
                    Exit message will be given if required.
                  </div>
                  <div className="text">
                    Pre-Market Outlook by SMS and whatsapp.
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block-two col-md-4 col-sm-6 col-xs-12" data-aos="zoom-in">
              <div className="inner-box  services-card ">
                <div className="lower-box">
                  {/* <div className="post-info">Post: 15 April 2018</div> */}
                  <h3>
                    <Link to="/payment">Future Package</Link>
                  </h3>
                  <div className="text">
                    This package is suitable for intraday traders who deal in
                    Equity intraday/delivery Segment only
                  </div>
                  <div className="text">
                    1-2 Intraday Stock Future/Derivatives trading tips with high
                    accuracy.
                  </div>
                  <div className="text">
                    Clear enter level, Target level and Stoploss level will be
                    mentioned.
                  </div>
                  <div className="text">
                    Exit message will be given if required
                  </div>
                  <div className="text">
                    Pre-Market Outlook by SMS & whatsapp
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block-two col-md-4 col-sm-6 col-xs-12" data-aos="fade-left">
              <div className="inner-box  services-card ">
                <div className="lower-box">
                  {/* <div className="post-info">Post: 15 April 2018</div> */}
                  <h3>
                    <Link to="/payment">Nifty Package</Link>
                  </h3>
                  <div className="text">
                    Package designed for traders who deal only in Nifty/ Bank
                    Nifty & Nifty option{" "}
                  </div>
                  <div className="text">
                    1-2 Intraday Nifty Future, Bank nifty & Option(Call/Put)*
                    tips daily with high accuracy.
                  </div>
                  <div className="text">
                    Clear enter level, Stoploss level and Target level will be
                    mentioned.
                  </div>
                  <div className="text">
                    Exit message will be given if required.
                  </div>
                  <div className="text">
                    Pre-Market Outlook by SMS & whatsapp
                  </div>
                </div>
              </div>
            </div>
          
        
       
            {/*News Block*/}
            <div className="news-block-two col-md-4 col-sm-6 col-xs-12" data-aos="fade-right">
              <div className="inner-box  services-card ">
                <div className="lower-box">
                  {/* <div className="post-info">Post: 15 April 2018</div> */}
                  <h3>
                    <Link to="/payment">Stock Option</Link>
                  </h3>
                  <div className="text">
                    This Package is suitable for Option Traders who deal in
                    Stock and Nifty Segment
                  </div>
                  <div className="text">
                    6-8 calls Option Trading Nifty Future & Future Stock
                    (Call/Put) Intraday Tips in a Month with high accuracy{" "}
                  </div>
                  <div className="text">1-2 Positional calls in a Month</div>
                  <div className="text">
                    Exit message will be given if required.
                  </div>
                  <div className="text">
                    Pre-Market Outlook by SMS & whatsapp
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block-two col-md-4 col-sm-6 col-xs-12" data-aos="zoom-in">
              <div className="inner-box  services-card ">
                <div className="lower-box">
                  {/* <div className="post-info">Post: 15 April 2018</div> */}
                  <h3>
                    <Link to="/payment">Combo Package</Link>
                  </h3>
                  <div className="text">
                    This package is suitable for intraday traders who deal in
                    cash @ Stock F&O Both Segment
                  </div>
                  <div className="text">
                    1-2 Intraday Cash & Stock Future/Derivatives trading tips
                    with high accuracy
                  </div>
                  <div className="text">
                    Preopening Calls facilities available. most probably 9:15am
                    to 9:20am.
                  </div>
                  <div className="text">
                    Exit message will be given if required.
                  </div>
                  <div className="text">
                    Pre-Market Outlook by SMS & whatsapp
                  </div>
                </div>
              </div>
            </div>

            <div className="news-block-two col-md-4 col-sm-6 col-xs-12" data-aos="fade-left">
              <div className="inner-box  services-card ">
                <div className="lower-box">
                  {/* <div className="post-info">Post: 15 April 2018</div> */}
                  <h3>
                    <Link to="/payment">Diamond Package</Link>
                  </h3>
                  <div className="text">
                    Package suitable for Investors who want less but highly
                    accurate tips for long /short term
                  </div>
                  <div className="text">
                    We offer precise market opening index levels, providing a
                    valuable foundation for your trading day. Additionally, our
                    expertise extends to specialized morning trades in both
                    index and commodity levels, ensuring you benefit from
                    tailored strategies for optimal trading outcomes.
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
      </section>

      {/*Why Us Section*/}
      <section
          className="why-us mb-5"
         
          style={{ backgroundImage: "url(assets/images/background/5.jpg)" }}
        >
          <div className="outer-container clearfix" >
            {/*Content Column*/}
            <div className="content-column">
              <div className="inner" >
                {/*Heading*/}
                <div className="sec-title-two light" data-aos="zoom-in">
                  <h1>Why Choose Us?</h1>
                </div>
                <div className="text" data-aos="zoom-in">
                  We’re with You Every Step of the Way: A single call connects
                  you to our unwavering support.
                </div>
                <div className="text" data-aos="zoom-in">
                  Tech-Powered & Timely: Real-time recommendations delivered via
                  SMS & Email during market hours.
                </div>
                <div className="text" data-aos="zoom-in">
                  Expertise and Proprietary Solutions: Over 50 years of
                  collective experience and advanced algorithms committed to
                  growing your wealth.
                </div>
                <div className="text" data-aos="zoom-in">
                  Guidance Throughout Your Investment Journey: Count on our
                  guidance from entry to exit levels, ensuring you’re never
                  alone.
                </div>

                <div className="link-box" >
                  <Link
                    to={`tel:${phoneNumber}`}
                    class="theme-btn btn-style-two"
                    data-aos="fade-down"
                  >
                    Get Start
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}
