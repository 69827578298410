import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200
});
export default function About() {
  const phoneNumber = "+91 9977323535";

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1> About</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in"> About</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="about-two hire mx-2">
        <div class="outer-container clearfix">
          <div class="image-column">
            <div class="image-layer"></div>
          </div>

          <div class="content-column">
            <div class="clearfix">
              <div class="inner">
                <div class="sec-title-two" data-aos="zoom-in">
                  <h1>ASSURE MARKET RESEARCH</h1>
                </div>

                <div class="text" data-aos="zoom-in">
                  <p>
                    Assure Market Research stands as a premier SEBI Registered
                    Research Analyst (SEBI Registration Number – INH000011802)
                    in India. We embody the next generation of Equity research
                    analysts and research recommendations service providers,
                    offering unparalleled services enriched with distinctive and
                    advanced features. Our track record boasts the industry’s
                    highest client retention ratio, a testament to our
                    excellence.
                  </p>
                  <p>
                    Eqwires introduces a pioneering rewards-based service,
                    setting a new standard for result-oriented offerings. Each
                    of our services is equipped with standard features,
                    including dedicated support, a personal Relationship
                    Manager, and comprehensive stock analysis, among others.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="about-two hire mx-2">
        <div class="outer-container clearfix">
          <div class="content-column">
            <div class="clearfix">
              <div class="inner">
                <div class="sec-title-two" data-aos="zoom-in">
                  <h1>WHO WE ARE</h1>
                </div>

                <div class="text" data-aos="zoom-in">
                  <p>
                    Assure Market Research is a SEBI registered Research
                    Analyst, comprised of a highly skilled team with extensive
                    experience in stock market research and analysis. We embrace
                    the potency of innovation, creativity, and knowledge. Our
                    meticulous research and analysis not only yield robust and
                    outcome-driven insights but also deliver gratifying results
                    to our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="content-column">
            <div class="clearfix">
              <div class="inner">
              <div class="sec-title-two">
                </div>
                <div class="text" data-aos="zoom-in">
                  <p>
                    Ranking among the leading SEBI registered Research Analysts
                    in India, Assure Market Research is committed to providing
                    an unparalleled service experience to our clients. Our team
                    consists of dedicated and passionate professionals who
                    tirelessly serve our clients around the clock. Ensuring
                    service and support satisfaction remains our foremost
                    objective.”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




  {/* apooinment */}

  <section class="call-to-action">
        <div class="auto-container">
          <div class="clearfix"data-aos="zoom-in">
            <h2>Schedule Your Appointment Today</h2>
          </div>
          <div class="clearfix" data-aos="zoom-in">
            <h2>
              India’s  Top-Notch SEBI Registered Research Analyst
            </h2>
          </div>
          <div class="clearfix" data-aos="zoom-in">
            <Link to={`tel:${phoneNumber}`} class="theme-btn btn-style-four">
              Contact us
            </Link>
          </div>
        </div>
      </section>

  
  {/*Why We Section*/}
  <section className="why-we">
    <div className="auto-container">
      <div className="row clearfix">
        {/*Text Column*/}
        <div className="text-column pull-right col-md-6 col-sm-12 col-xs-12">
          <div className="inner" data-aos="zoom-in">
            <h2>
            OUR VISION
            </h2>
            <div className="text" data-aos="zoom-in">
            “We are driven and motivated by our overarching vision:
            </div>
            <div className="text" data-aos="zoom-in">
            To attain global recognition for our commitment to quality, transparency, integrity, and supportive services.
            </div>
            <div className="text" data-aos="zoom-in">
            We firmly believe that everyone deserves informed guidance in trading and investment within the stock market. Our delight stems from innovation and expansive foresight, as our mission revolves around eliminating obstacles that may hinder individuals from venturing into the world of stock market investments. We are consistently enthusiastic about aiding you on your investment journey within the stock market.”
            </div>
        
         
          </div>
        </div>
        {/*Image Column*/}
        <div className="image-column col-md-6 col-sm-8 col-xs-12">
          <div className="inner">
            <figure className="image">
              <img src="assets/images/resource/image-1.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>



  

      {/*Why Us Section*/}
      <section
          className="why-us mb-5"
         
          style={{ backgroundImage: "url(assets/images/background/5.jpg)" }}
        >
          <div className="outer-container clearfix" >
            {/*Content Column*/}
            <div className="content-column">
              <div className="inner" >
                {/*Heading*/}
                <div className="sec-title-two light" data-aos="zoom-in">
                  <h1>Why Choose Us?</h1>
                </div>
                <div className="text" data-aos="zoom-in">
                  We’re with You Every Step of the Way: A single call connects
                  you to our unwavering support.
                </div>
                <div className="text" data-aos="zoom-in">
                  Tech-Powered & Timely: Real-time recommendations delivered via
                  SMS & Email during market hours.
                </div>
                <div className="text" data-aos="zoom-in">
                  Expertise and Proprietary Solutions: Over 50 years of
                  collective experience and advanced algorithms committed to
                  growing your wealth.
                </div>
                <div className="text" data-aos="zoom-in">
                  Guidance Throughout Your Investment Journey: Count on our
                  guidance from entry to exit levels, ensuring you’re never
                  alone.
                </div>

                <div className="link-box" >
                  <Link
                    to={`tel:${phoneNumber}`}
                    class="theme-btn btn-style-two"
                    data-aos="fade-down"
                  >
                    Get Start
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}
