import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200,
});
export default function UserPrivacyPolicy() {
  const phoneNumber = "+91 9977323535";

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Privacy Policy</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                {" "}
                Privacy Policy
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="about-two hire mx-2">
        <div class="outer-container clearfix">
          <div class="image-column">
            <div class="image-layer"></div>
          </div>
        </div>
      </section>

      <div className="container ">
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Privacy Policy
        </h1>
        <div className="">
          <p>
          Assure Market  respects and values the right to Privacy of each
            and every individual. We are esteemed by the relationship and by
            becoming our clients you have a promise from our side that we shall
            remain loyal to all our clients and non clients whose information
            resides with us. This Privacy Policy of Assure Market Research  applies to
            the current clients, subscribers, users as well as former clients
            who are registered on   <a href="https://assuremarketresearch.com/" target="_blank">
            https://www Assure Market Research{" "}
            </a> Below are the
            word by word credentials of our Privacy Policy:
          </p>
        </div>
        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          What information do we collect?
        </h2>

        <div className="">
          <p>
            We collect information from you when you register on our site,
            subscribe to our newsletter or fill out a form.
            <br />
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your: name, phone number, address and / or e-mail
            address. You may, however, visit our site anonymously.
          </p>
        </div>
        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          What do we use your information for?
        </h2>

        <div className="">
          <p>
            Any of the information we collect from you may be used in one of the
            following ways:
            <br />
            To improve customer service (your information helps us to more
            effectively respond to your customer service requests and support
            needs)
            <br />
            To process transactions
            <br />
            Your information, whether public or private, will not be sold,
            exchanged, transferred, or given to any other company for any reason
            whatsoever, without your consent, other than for the express purpose
            of delivering the purchased product or service requested.
            <br />
            To administer a contest, promotion, survey or other site feature
            <br />
            To send periodic emails
            <br />
            The email address you provide for order processing, may be used to
            send you information and updates pertaining to your order, in
            addition to receiving occasional company news, updates, related
            product or service information, etc.
          </p>
        </div>
        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          How do we protect your information?
        </h2>
        <div className="">
          <p>
            {" "}
            Mailing lists are intellectual property of the company and not
            shared with outside third parties at any cost. Your personal data
            cannot be accessed by visitors of the website. We use third party
            software to maintain mailing lists that are secured by them. We do
            not collect any payment information. Third party Payment Gateways
            are used to offer secure transaction services.
          </p>
        </div>
        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Do we use cookies?
        </h2>
        <div className="">
          <p>
            Yes (Cookies are small files that a site or its service provider
            transfers to your computer s hard drive through your Web browser (if
            you allow) that enables the sites or service providers systems to
            recognize your browser and capture and remember certain information
          </p>
          <br />
          <p>
            We may use cookies to help us remember and process the items in your
            shopping cart, understand and save your preferences for future
            visits and compile aggregate data about site traffic and site
            interaction so that we can offer better site experiences and tools
            in the future. We may contract with third-party service providers to
            assist us in better understanding our site visitors. These service
            providers are not permitted to use the information collected on our
            behalf except to help us conduct and improve our business.
          </p>
          <br />
          <p>
            We use Google Analytics and Google Remarketing services. Visitors
            can opt-out of Google Analytics for Display Advertising and
            customize Google Display Network ads using the Ads Settings.
            Third-party vendors, including Google, show our ads on sites across
            the Internet. We and third-party vendors, including Google, use
            first-party cookies (such as the Google Analytics cookie) and
            third-party cookies (such as the DoubleClick cookie) together to
            inform, optimize, and serve ads based on someone s past visits to
            your website and measure ad impressions.
          </p>{" "}
        </div>

        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Do we disclose any information to outside parties?
        </h2>
        <div className="">
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information. This does not include trusted
            third parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our site policies, or protect ours or others rights,
            property, or safety. However, non-personally identifiable visitor
            information may be provided to other parties for marketing,
            advertising, or other uses.
          </p>
        </div>
        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Third party links
        </h2>
        <div className="">
          <p>
            Occasionally, at our discretion, we may include or offer third party
            products or services on our website. These third party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.{" "}
          </p>
        </div>
        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Terms and Conditions
        </h2>
        <div className="">
          <p>
            Please also visit our Terms and Conditions section establishing the
            use, disclaimers, and limitations of liability governing the use of
            our website at{" "}
            <a href="https://assuremarketresearch.com/" target="_blank">
              Assure Market Research{" "}
            </a>
          </p>
        </div>
        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Your Consent
        </h2>
        <div className="">
          <p>By using our site, you consent to our privacy policy.</p>
        </div>
        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Changes to our Privacy Policy
        </h2>
        <div className="">
          <p>
            If we decide to change our privacy policy, we will post those
            changes on this page.
          </p>
        </div>
        <h2 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Contacting Us
        </h2>
        <div className="mb-3">
          <p>
            If there are any questions regarding this privacy policy you may
            contact us using the information below.
          </p>
      
          <a href="https://assuremarketresearch.com/" target="_blank">
            Assure Market Research{" "}
          </a>
          <br />
          <a href="mailto:info@assuremarketresearch.com">
            info@assuremarketresearch.com
          </a>
        </div>
      </div>
    </>
  );
}
