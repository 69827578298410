import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200,
});
export default function AmlPolicy() {
  const phoneNumber = "+91 9977323535";

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Aml Policy </h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                {" "}
                Aml Policy
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="about-two hire mx-2">
        <div class="outer-container clearfix">
          <div class="image-column">
            <div class="image-layer"></div>
          </div>
        </div>
      </section>
      <div className="container ">
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Provisions of Prevention of Money Laundering Act, 2002
        </h1>
        <div className="">
          <p>
            Prevention of Money Laundering Act, 2002 (PMLA) forms the core of
            legal framework put in place by India to combat money laundering and
            related crimes. PMLA and the Rules notified there under came into
            force from 1st July, 2005. Under PMLA, all the entries registered
            with SEBI are required to furnish information of all the suspicious
            transactions whether or not made in cash to FIU-IND. Under Section3
            of PMLA, projecting of crime as untainted property is an offence of
            money laundering liable to be punishment under section 4 of the
            PMLA.
          </p>

          <p className="my-2">
            Money Laundering involves disguising financial assets so that they
            can be used without detection of the illegal activity that produced
            them. Through money laundering, the launderer transforms the
            monetary proceeds derived from criminal activity into funds with as
            apparently legal source.
          </p>

          <p className="my-2">
            Financial Intelligence Unit-India (FIU-IND) is the central national
            agency of India responsible for receiving, processing, analysing and
            disseminating information of suspect financial transactions. FIU-IND
            is also responsible for coordinating and strengthening efforts of
            national and international intelligence, investigation and
            enforcement agencies in combating money laundering and related
            crimes.
          </p>
          <p className="my-2">
            Section 2 (1) (g) of PMLA Rules defines suspicious transaction
            whether or not made
          </p>
        </div>
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          in cash which, to a person acting in good faith:
        </h1>
        <div className="refund">
          <ul style={{ listStyleType: "circle!important" }}>
            <li>
              Gives rise to a reasonable ground of suspicious that it may
              involve the proceeds of crime: or
            </li>
            <li>
              Appears to be made in circumstances of unusual or unjustified
              complexity; or
            </li>
            <li>
              Appears to have no economic rationale or bonafied purpose; or
            </li>
            <li>
              Gives rise to a reasonable ground of suspicious that it may
              involve facing of the activities relating to terrorism
            </li>
          </ul>
        </div>
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Policy and Procedures for Anti Money Laundering Measures
        </h1>
        <div className="">
          <p>
            The policy and procedures as outlined below provides a general
            background on the subjects of money laundering and terrorist
            financing summarizes the main provisions of the applicable
            anti-money laundering and anti-terrorist financing legislation in
            India and provides guidance on the practical implications of the
            Act. The same also sets out the steps that a registered intermediary
            and any of its representatives, should implement to discourage and
            identify any money laundering or terrorist financing activities.{" "}
          </p>
          <p className="my-2">
            The Prevention of Money Laundering Act, 2002 has come into effect
            from 1st July 2005. Necessary Notifications / Rules under the said
            Act have been published in the Gazette of India on 1st July 2005 by
            the Department of Revenue, Ministry of Finance, Government of India.
          </p>
          <p className="my-2">
            As per the provisions of the Act, every banking company, financial
            institution (which includes chit fund company, a co-operative bank,
            a housing finance institution and a non banking financial company)
            and intermediary (which Includes a stock-broker, sub broker, share
            transfer agent, banker to an issue, trustee to a trust deed,
            registrar to an issue, merchant banker, underwriter, portfolio
            manager, investment adviser and any other intermediary associated
            with securities market and registered under section 12 of the
            Securities and Exchange Board of India Act, 1992) shall have to
            maintain a record of all the transactions; the nature and value of
            which has been prescribed in the Rules under the PMLA. Such
            transactions include:
          </p>
        </div>

        <div className="refund">
          <ul style={{ listStyleType: "circle!important" }}>
            <li>
              All cash transactions of the value of more than Rs 10 lacs or its
              equivalent in foreign currency.
            </li>
            <li>
              All series of cash transactions integrally connected to each other
              which have been valued below Rs 10 lakhs or its equivalent in
              foreign currency where such series of transactions take place
              within one calendar month.
            </li>
            <li>
              All suspicious transactions whether or not made in cash and
              including, inter-alia, credits or debits into from any
              non-monetary account such as demat account, security account
              maintained by the registered intermediary.
            </li>
          </ul>
          <p className="my-1">
            We should adopt written procedures to implement the anti-money
            laundering provisions as envisaged under the Anti Money Laundering
            Act, 2002. Such procedures should include inter alia, the following
            three specific parameters which are related to the overall
          </p>

          <h2 className="fs-1" style={{ fontWeight: 500 }}>
            {" "}
            Client Due Diligence Process:
          </h2>
          <ul style={{ listStyleType: "circle!important" }}>
            <li>a) Policy for acceptance of clients</li>
            <li>b) Procedure for identifying the clients</li>
            <li>
              c) Transaction monitoring and reporting especially Suspicious
              Transactions Reporting (STR)
            </li>
          </ul>
          <h2 className="fs-1" style={{ fontWeight: 500 }}>
            {" "}
            Client Due Diligence Process
          </h2>
          <p className="my-1">
            The customer due diligence (“CDD”) measures comprise the following:
          </p>

          <h1 className="fs-1" style={{ fontWeight: 500 }}>
            {" "}
            a. Obtaining sufficient information in order to identify persons who
            beneficially own or control securities account.
          </h1>
          <p className="my-1">
            As an organization providing Research Analyst Services, details of
            securities account of clients are not shared with us in the process
            of delivering services and execution services are not part of our
            service package. Accordingly, identifying the beneficial owner or
            controlling party of the securities account of the client is the
            responsibility of the broker handling the security account of the
            client.
          </p>
          <h1 className="fs-1" style={{ fontWeight: 500 }}>
            b. Verify the customer’s identity{" "}
          </h1>
          <p className="my-1">
            We adhere to SEBI KYC (Know Your Client) REGISTRATION AGENCY
            REGULATIONS and any subsequently amended regulations to verify the
            customer's identity in accordance with PMLA requirements.
          </p>
          <p className="my-1">
            As registered members of KRA Agencies including CVL KRA, NDML KRA,
            and BSE KRA, we validate and download the client's information from
            the KRA system.
          </p>
          <p className="my-1">
            If the status of the clients or their KYC information changes, we
            update the information on the KRA system and keep the relevant
            physical documents
          </p>
          <h1 className="fs-1" style={{ fontWeight: 500 }}>
            c. Identify beneficial ownership and control, i.e. determine which
            individual(s) ultimately own(s) or control(s) the customer and/or
            the person on whose behalf a transaction is being conducted
          </h1>
          <p className="my-1">
            Transaction data is not handled by us as the client doesn't share
            the such data with us as part of our research service. We provide
            non-discretionary research recommendation service, execution of
            which is on the discretion of the client, and execution is handled
            by client themselves. Client don't share any executional or
            transactional data with us.{" "}
          </p>
          <p className="my-1">
            Accordingly, identifying the beneficial owner or controlling party
            of the securities account of the client is the responsibility of the
            broker handling the security account of the client.{" "}
          </p>
          <h1 className="fs-1" style={{ fontWeight: 500 }}>
            a.Policy for acceptance of clients
          </h1>
          <p className="my-1">
            Following safeguards are to be followed while accepting the clients:
          </p>
          <ul style={{ listStyleType: "circle!important" }}>
            <li>
              No account is opened in a fictitious / benami name or on an
              anonymous basis.
            </li>
            <li>
              Ensure that an account is not opened where the you are unable to
              apply appropriate client’s due diligence measures / collect basic
              KYC detail i.e., PAN card number
            </li>
            <li>Ensure that the client is KYC registered.</li>
            <li>
              The client should not be permitted to act on behalf of another
              person /entity for service delivery
            </li>
            <li>
              Do not accept clients with identity matching with banned person/
              entity as per SEBI/ Stock Exchanges in capital market: -check
              whether the client ‘s identity matched with persons debarred/
              banned by SEBI before opening of account. If you find them in that
              list then do no open the account. List may be verified using below
              link
              <a
                href="https://www.bseindia.com/investors/debent.aspx?expandable=4"
                target="_blank"
              >
                BSE Debarred Entities List
              </a>
              <a
                href="https://www.nseindia.com/regulations/member-sebi-debarred-entities"
                target="_blank"
              >
                NSE Debarred Entities List
              </a>
            </li>
            <li>
              Conduct Risk assessment which takes into account any country
              specific information using the updated list of individuals and
              entities who are subjected to sanction measures as required under
              the various United Nations' Security Council Resolutions. Do not
              onboard a client who is present in the list. These can be accessed
              at the URL
              <a
                href="http://www.un.org/sc/committees/1267/aq_sanctions_list.shtml"
                target="_blank"
              >
                UN Security Council Resolutions - AQ Sanctions List
              </a>{" "}
              and
              <a
                href="http://www.un.org/sc/committees/1988/list.shtml"
                target="_blank"
              >
                UN Security Council Resolutions - 1988 List
              </a>
            </li>
          </ul>
          <h1 className="fs-1" style={{ fontWeight: 500 }}>
            {" "}
            b. Procedure for identifying the clients
          </h1>
          <p className="my-1">
            The `Know Your Client' (KYC) policy should be strictly observed
            concerning the client identification procedures which need to be
            carried out at the time of establishing the client relationship i.e.
            onboarding the client. 
          </p>
          <p className="my-1">
            The client should be identified by using reliable sources including
            documents/information. Obtain adequate information to satisfactorily
            establish the identity of each new client and the purpose of the
            intended nature of the relationship.
          </p>
          <p className="my-1">
            The information should be adequate to satisfy competent authorities
            (regulatory / enforcement authorities) in the future that due
            diligence was observed in compliance with the Guidelines. Each
            original document should be seen before acceptance of a copy and it
            should be verified and duly attested.
          </p>
          <p className="my-1">
            Failure by prospective clients to provide satisfactory evidence of
            identity should be noted and reported to the higher authority within
            the organization.
          </p>
          <p className="my-1">
            SEBI has prescribed the minimum requirements relating to KYC for
            certain classes of registered intermediaries from time to time.
            Taking into account the basic principles enshrined in the KYC norms,
            internal guidelines should be followed in dealing with clients and
            legal requirements as per the established practices. Also, maintain
            continuous familiarity and follow-up where it notices
            inconsistencies in the information provided by the client. The
            principles enshrined in the PML Act, 2002 as well as the SEBI Act,
            1992 should be followed, so that the Firm is aware of the clients on
            whose behalf it is dealing
          </p>
          <h1 className="fs-1" style={{ fontWeight: 500 }}>
            {" "}
            c. Maintenance of record
          </h1>
          <p className="my-1">
            All the records of the clients are to be maintained for a minimum
            period of 10 Years or in case of any regulatory action till the time
            the same is resolved.
          </p>
          <h1 className="fs-1" style={{ fontWeight: 500 }}>
            {" "}
            d. Audit
          </h1>
          <p className="my-1">
            Audit of RA activities to be done by an independent professional as
            allowed by the regulation. Any observations of audit to be taken on
            priority basis and corrective actions to be initiated.
          </p>
          <h1 className="fs-1" style={{ fontWeight: 500 }}>
            {" "}
            e. Transaction monitoring and reporting especially Suspicious
            Transactions Reporting (STR)
          </h1>
          <p className="my-1">
            Only Transaction encountered while delivering the service is
            collection of fees as we don’t have access to the execution of
            transaction data of the clients. Accordingly, the fee collection
            should be through our bank account only. Further, no cash
            transaction should be allowed for fee payment by the clients.
          </p>
          <p className="my-1">
            The nature and value of transactions, which has been prescribed in
            the Rules under the PMLA to maintain and record includes:
          </p>
          <p className="my-1">
            All cash transactions of the value of more than Rs 10 lacs or its
            equivalent in foreign currency. o All series of cash transactions
            integrally connected to each other which have been valued below Rs
            10 lakhs or its equivalent in foreign currency where such series of
            transactions take place within one calendar month. o All suspicious
            transactions whether or not made in cash and including, inter-alia,
            credits or debits into from any non-monetary account such as demat
            account, security account maintained by the registered intermediary
          </p>
          <p className="my-1">
            Any suspicious transactions will be immediately notified to the
            Compliance Officer. The notifications may be done in the form of a
            detailed report with specific references to the clients,
            transactions and the nature/reason of suspicion. The compliance
            staff members will have timely access to customer identification
            data and other CDD information, transaction records and other
            relevant information.{" "}
          </p>
          <p className="my-1">
            Compliance Officer will carefully go through all the reporting
            requirements and formats as per the provision of PMLA
          </p>
          <ul style={{ listStyleType: "circle!important" }}>
            <li>
              a) The Principal Officer will be responsible for timely submission
              of CTR and STR to FIU-IND
            </li>
            <li>
              b) Utmost confidentially will be maintained in filling of CTR and
              STR to FIU- IND.
            </li>
            <li>
              c) The reports will be transmitted by speed/registered post/fax at
              the notified address.
            </li>
            <li>
              d) No nil reporting will be made to FIU-IND in case there are no
              cash/suspicious transaction to be reported.
            </li>
          </ul>
          <h1 className="fs-1" style={{ fontWeight: 500 }}>
            {" "}
            Reporting to FIU – India
          </h1>
          <div class="row">
            In terms of the PMLA rules, BMWA will report information relating to
            cash and suspicious transactions to The Director, Financial
            Intelligence Unit India (FIU – IND) at the following address
            <div class="col-md-6">
              <h4>Director, FIU – IND</h4>
              <p>Financial Intelligence Unit India</p>
              <p>6th floor, Hotel Samrat Chanakyapuri</p>
              <p>New Delhi – 110021</p>
            </div>
          </div>
          <div class="role-description">
            <h3>Role of Principal Officer</h3>
            <ul>
              <li>
                Communicating the policy on prevention of Money laundering to
                the employees.
              </li>
              <li>
                Receiving reports from employees for any suspicious dealing
                noticed by them.
              </li>
              <li>
                Clarification of any queries from employees on this matter.
              </li>
              <li>
                Ensuring that the employees dealing with the clients/prospective
                clients are aware of the guidelines and are advised to follow
                the same strictly.
              </li>
              <li>
                Report any suspicious transactions to appropriate authorities.
              </li>
              <li>
                Handle compliance function and to ensure compliance with the
                policies, procedures, and controls relating to the prevention of
                ML and TF.
              </li>
              <li>Evaluate the process in case any gaps are identified.</li>
            </ul>
          </div>

          <div class="role-description">
            <h3>Role of On-Boarding Staff</h3>
            <ul>
              <li>
                Primary responsibility of compliance is on the on-boarding staff
                since they deal face-to-face with customers.
              </li>
              <li>
                On-boarding staff to carry out KYC process/customer due
                diligence process/any further checks required as per our process
                during new business and renewal.
              </li>
              <li>
                Default on carrying out obligation under AML law can attract
                action as per set Firm policies.
              </li>
              <li>
                If you come to know of any suspicious activity, you have to
                bring that to our notice.
              </li>
            </ul>
          </div>
          <div class="policy-communication">
            <h3>Communication of Policy</h3>
            <p>
              Copy of the above policy is to be provided to all the management
              and relevant staff that handle account information, securities
              transactions, money, and client records etc., whether in branches,
              departments, or subsidiaries.
            </p>
            <p>
              An internal session on awareness of the above policy is to be
              conducted on a yearly basis in the 1st week of April to spread
              awareness of the same among all the relevant person(s).
            </p>
          </div>
          <div class="compliance-requirements">
            <h3>
              Compliance with Relevant Statutory and Regulatory Requirements
            </h3>
            <p>
              It is to be ensured that the activities are in compliance with all
              the relevant statutory and regulatory requirements.
            </p>
          </div>
          <div class="cooperation-law-enforcement">
            <h3>Co-operation with Relevant Law Enforcement Authorities</h3>
            <p>
              As and when sought, appropriate information of the clients as
              maintained is to be shared with the relevant law enforcement
              authorities, and timely disclosures of the information are to be
              made as per the requirement.
            </p>
          </div>
          <div class="policy-review">
            <h3>Review of Policy and Procedures</h3>
            <p>
              The management of the research analyst firm reviews the policies
              and procedures on the prevention of money laundering (ML) and
              terrorist financing (TF) to ensure their effectiveness whenever
              there is a change in regulatory guidelines related to the
              prevention of ML and TF.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="font-weight-bold">Mr. Sandeep Gome </p>
            <p class="font-italic">For,</p>
            <p class="font-weight-bold">Assure Market Research</p>
            <p class="font-italic">SEBI Registered Research Analyst</p>
            <p class="font-italic">Registration No. INH000011802</p>
          </div>
        </div>
      </div>
    </>
  );
}

