import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer className="main-footer footer-style-two">
      {/*Footer Upper*/}
      <div className="footer-upper">
        <div className="auto-container">
          <div className="row clearfix" data-aos="slide-up">
            {/*Big Column*/}
            <div className="big-column col-md-6 col-sm-12 col-xs-12">
              <div className="row clearfix">
                {/*Footer Column*/}
                <div className="footer-column col-md-9 col-sm-6 col-xs-12">
                  <div className="footer-widget">
                    <div className="widget-inner d-flex align-items-center justify-content-center flex-column">
                      <div className="logo" style={{ width: "100px" }}>
                        <img src="assets/images/Assurelogo.png" alt="" />
                      </div>
                      <div className="text px-2 text-justify">
                        Assure Research Analyst stands as a premier SEBI
                        Registered Research Analyst (SEBI Registration Number –
                        INH000011802) in India, spearheading the next generation
                        of equity research analysis and innovative research
                        recommendation services. Our commitment to excellence is
                        underscored by unparalleled features and distinctive
                        offerings. With a remarkable track record of client
                        retention, we set a benchmark in the industry.
                      </div>
                      {/* <div className="social-links">
                        <ul className="clearfix">
                          <li>
                            <Link to="/">
                              <span className="fa fa-facebook-f" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              <span className="fa fa-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              <span className="fa fa-skype" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              <span className="fa fa-instagram" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/">
                              <span className="fa fa-linkedin" />
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/*Footer Column*/}
                <div className="footer-column col-md-3 col-sm-6 col-xs-12">
                  <div className="footer-widget links-widget">
                    <div className="widget-inner">
                      <h3>Short Link</h3>
                      <div className="links">
                        <ul>
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>
                            <Link to="/Services">Service</Link>
                          </li>
                          <li>
                            <Link to="/About">About Us</Link>
                          </li>
                          <li>
                            <Link to="/pricing">Pricing</Link>
                          </li>
                          <li>
                            <Link to="/Faq">FAQ</Link>
                          </li>
                          <li>
                            <Link to="/contact">Cantact Us</Link>
                          </li>
                          <li>
                            <Link to="/payment">Payment</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Big Column*/}
            <div className="big-column col-md-6 col-sm-12 col-xs-12">
              <div className="row clearfix">
                {/*Footer Column*/}

                <div className="footer-column  offset-2  col-md-4 col-sm-12 col-xs-4">
                  <div className="footer-widget info-widget">
                    <div className="widget-inner">
                      <h3>Quick Links</h3>
                      <div className="links">
                        <ul>
                          <li>
                            <Link to="/User-Privacy-Policy">
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link to="/User-Refund-Policy">Refund Policy</Link>
                          </li>
                          <li>
                            <Link to="/User-Terms-and-Conditions">
                              Terms and Conditions
                            </Link>
                          </li>
                          <li>
                            <Link to="/User-Warning">
                              {" "}
                              Warning & Disclaimer
                            </Link>
                          </li>
                          <li>
                            <Link to="/User-AmlPolicy">Aml Policy</Link>
                          </li>
                          <li>
                            <Link to="/User-Disclaimer">Disclaimer</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="footer-column col-md-6 col-sm-12 col-xs-8">
                  <div className="footer-widget info-widget">
                    <div className="widget-inner">
                      <h3>Contact Us</h3>
                      <div className="info">
                        <ul>
                          <li className="clearfix">
                            <strong>Address: </strong>{" "}
                            <span className="txt">
                              <a
                                href="https://www.google.com/maps/search/?api=1&query=HALL+NO+3,+PLOT+NO+10+B,+AMAN+COMPLEX,+GOVIND+GARDEN,+GOVVINDPURA,+RAISEN+ROAD,+BHOPAL,+462023,+MADHYA+PRADESH,+INDIA"
                                target="_blank"
                              
                              >
                                Hall No 3, Plot No 10 B, Aman Complex, Govind
                                Garden, Govvindpura, Raisen Road, Bhopal -
                                462023, Madhya Pradesh, India
                              </a>
                            </span>
                          </li>
                          <li className="clearfix">
                            <strong>Phone: </strong>{" "}
                            <span className="txt">
                              <a href="tel:+919977323535">+91 9977323535</a>
                            </span>
                          </li>
                          <li className="clearfix">
                            <strong>Email: </strong>{" "}
                            <span className="txt">
                              <a href="mailto:info@assuremarketresearch.com">
                                info@assuremarketresearch.com
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* content  */}

            <div className="big-column col-md-12 col-sm-12 col-xs-12">
              <div className="row clearfix">
                <div className="text px-2 text-justify">
                  "Registration granted by SEBI, and certification from NISM in
                  no way guarantee performance of the intermediary or provide
                  any assurance of returns to investors." "The securities
                  quoted, if any are for illustration only and are not
                  recommendatory." "Investments in securities market are subject
                  to market risks. Read all the related documents carefully
                  before investing."
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Footer Bottom*/}
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="copyright">
                © 2023 Developed by Code with MR S.D
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
