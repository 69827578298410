import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
export default function Princing() {
  const phoneNumber = "+91 9977323535";
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/paymentbgcard.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Pricing</h1>
          <div className="bread-crumb" data-aos="zoom-in">
            <ul className="clearfix">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                {" "}
                Pricing
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/*News Section*/}
      <section className="news-section-two">
        <div className="auto-container">
          {/*Heading*/}
          <div className="sec-title-two centered" data-aos="zoom-in">
            <h1>
              Our efforts are dedicated to assisting you in cultivating
              financial well-being.
            </h1>
            <div class="clearfix" data-aos="zoom-in">
              <p>
                Assure Market Research is a distinguished SEBI registered
                Research Analyst firm that offers research calls across all
                market segments. This commitment has enabled us to cater to a
                wide spectrum of traders. Our focus on product and service
                innovation, electronic system development, and global reach has
                been aimed at meeting the evolving needs of our clients. We
                prioritize cultivating a stimulating and professional work
                environment, ensuring access to cutting-edge technology. This,
                in turn, empowers us to deliver the finest stock tips to our
                trading community, resulting in consistent profits.
              </p>
            </div>
            <div class="clearfix" data-aos="zoom-in">
              <p>
                The quality of our research call tips has withstood the test of
                time, garnering recognition as unbiased, meticulously
                researched, and effective intraday stock tips that consistently
                perform. Our readers have championed our approach, acknowledging
                its ability to generate impressive outcomes.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*Why We Section*/}
      <section className="why-we ">
        <div className="sec-title centered">
          <h1 data-aos="zoom-in">Our Packages</h1>
          <div className="text" data-aos="zoom-in">
            We provide reliable & accurate calls in stock market. Our
            professional stock experts offer best tips Service for Indian Stock
            Market.
          </div>
        </div>
        <div className="pricing">
          <div className="row prow clearfix">
            {/*Text Column*/}

            <div
              className=" paycard text-column pull-right col-md-4 col-sm-12 col-xs-12"
              data-aos="fade-right"
            >
              <div className="inner">
                <h2>Equity & index combo</h2>

                <div className="row clearfix">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Duration </li>
                      <li>Quarterly </li>
                      <li>Half yearly</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Price</li>
                      <li>1,50,000 + 18%(GST)</li>
                      <li>2,50,000 + 18%(GST)</li>
                    </ul>
                  </div>
                </div>
                <div className="link-box">
                  <Link to="/payment" className="theme-btn btn-style-two">
                    Pay Now
                  </Link>
                </div>
              </div>
            </div>

            <div
              className=" paycard text-column pull-right col-md-4 col-sm-12 col-xs-12"
              data-aos="zoom-in"
            >
              <div className="inner">
                <h2>Bank nifty Future & option</h2>

                <div className="row clearfix">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Duration </li>
                      <li>Monthly </li>
                      <li>Quarterly</li>
                      <li>Half Yearly</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Price</li>
                      <li>15000 + 18%(GST)</li>
                      <li>35000 + 18%(GST)</li>
                      <li>60000 + 18%(GST)</li>
                    </ul>
                  </div>
                </div>
                <div className="link-box">
                  <Link to="/payment" className="theme-btn btn-style-two">
                    Pay Now
                  </Link>
                </div>
              </div>
            </div>

            <div
              className=" paycard text-column pull-right col-md-4 col-sm-12 col-xs-12"
              data-aos="fade-left"
            >
              <div className="inner">
                <h2>Stock option</h2>

                <div className="row clearfix">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Duration </li>
                      <li>Monthly </li>
                      <li>Quarterly</li>
                      <li>Half Yearly</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Price</li>
                      <li>15000 + 18%(GST)</li>
                      <li>35000 + 18%(GST)</li>
                      <li>60000 + 18%(GST)</li>
                    </ul>
                  </div>
                </div>
                <div className="link-box">
                  <Link to="/payment" className="theme-btn btn-style-two">
                    Pay Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row prow clearfix">
            {/*Text Column*/}

            <div
              className=" paycard text-column pull-right col-md-4 col-sm-12 col-xs-12"
              data-aos="fade-right"
            >
              <div className="inner">
                <h2>Index combo</h2>

                <div className="row clearfix">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Duration </li>
                      <li>Monthly </li>
                      <li>Quarterly </li>
                      <li>Half yearly</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Price</li>
                      <li>25000 + 18%(GST)</li>
                      <li>56000 + 18%(GST)</li>
                      <li>90000 + 18%(GST)</li>
                    </ul>
                  </div>
                </div>
                <div className="link-box">
                  <Link to="/payment" className="theme-btn btn-style-two">
                    Pay Now
                  </Link>
                </div>
              </div>
            </div>

            <div
              className=" paycard text-column pull-right col-md-4 col-sm-12 col-xs-12"
              data-aos="zoom-in"
            >
              <div className="inner">
                <h2>Bank nifty Future & option</h2>

                <div className="row clearfix">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Duration </li>
                      <li>Monthly </li>
                      <li>Quarterly</li>
                      <li>Half Yearly</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Price</li>
                      <li>15000 + 18%(GST)</li>
                      <li>35000 + 18%(GST)</li>
                      <li>60000 + 18%(GST)</li>
                    </ul>
                  </div>
                </div>
                <div className="link-box">
                  <Link to="/payment" className="theme-btn btn-style-two">
                    Pay Now
                  </Link>
                </div>
              </div>
            </div>

            <div
              className=" paycard text-column pull-right col-md-4 col-sm-12 col-xs-12"
              data-aos="fade-left"
            >
              <div className="inner">
                <h2>Diamand Packages</h2>

                <div className="row clearfix">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Duration </li>
                      <li>Monthly </li>
                      <li>Quarterly</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Price</li>
                      <li>65000 + 18%(GST)</li>
                      <li>1,55,000 + 18%(GST)</li>
                    </ul>
                  </div>
                </div>
                <div className="link-box">
                  <Link to="/payment" className="theme-btn btn-style-two">
                    Pay Now
                  </Link>
                </div>
              </div>
            </div>






          </div>



          
          <div className="row prow clearfix">
            <div
              className=" paycard text-column pull-right col-md-4 col-sm-12 col-xs-12"
              data-aos="fade-left"
            >
              <div className="inner">
                <h2>Bank Nifty (Premium)</h2>

                <div className="row clearfix">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Duration </li>
                      <li>Monthly </li>
                      <li>Quarterly</li>  
                      <li>Half Yearly</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <ul className="list-style-one">
                      <li>Price</li>
                      <li>25,000 + 18%(GST)</li>
                      <li>75,000 + 18%(GST)</li>
                      <li>1,35000 + 18%(GST)</li>
                    </ul>
                  </div>
                </div>
                <div className="link-box">
                  <Link to="/payment" className="theme-btn btn-style-two">
                    Pay Now
                  </Link>
                </div>
              </div>
            </div>
            </div>
        </div>
      </section>
    </>
  );
}
