import React, { useState,useEffect } from "react";
import Iframe from "react-iframe";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200
});
export default function Contact() {
  const phoneNumber = "+91 9977323535";

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    number: "",
    message: "",
  });
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can use formData to send the data wherever you need
    console.log("Form data submitted:", formData);
  
    // Create a query string from formData
    const queryString = Object.entries(formData)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
  
    // Predefined WhatsApp link with a message
    const whatsappLink = `https://wa.me/+919516144024?text=${encodeURIComponent(
      `I'm interested Name: ${formData.username},Email: ${formData.email},Number: ${formData.number},Message: ${formData.message}`
    )}`;
  
   // Predefined WhatsApp link with a message
  //  const whatsappLink = `https://wa.me/+916263817082?text=${encodeURIComponent(
  //   `Name: ${formData.Name}%0AEmail: ${formData.email}%0ANumber: ${formData.Sub}%0AMessage: ${formData.sms}`
  // )}`;

  // Redirect to WhatsApp link
  window.location.href = whatsappLink;


    // <a href={`https://wa.me/+916263817082?text=  Name: ${Name} Email: ${email}  Number:${Sub} Message: ${sms} `}
 
  };
  
  

  return (
    <>
      {/*Page Title*/}
      <section className="page-title">
        <div
          className="image-layer"
          style={{
            backgroundImage: "url(./assets/images/background/contact.webp)",
          }}
        />
        <div className="auto-container">
          <h1 data-aos="zoom-in">Contact With Us</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">Contact Us</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Contact Section*/}
      <section className="contact-section">
        <div className="auto-container">
          {/*Heading*/}
          <div className="sec-title centered">
            <h1 data-aos="zoom-in">Contact Us Now!</h1>
            <div  data-aos="zoom-in" className="text">
              We eagerly anticipate your feedback and inquiries. Whether you
              seek clarification on our services or any other matter, our team
              is poised to provide comprehensive answers to all your queries
            </div>
          </div>
          <div className="form-container">
            {/*Quote Form*/}
            {/* <div className="form-style-one quote-form">
              <form method="post" id="contact-form" onSubmit={handleSubmit}>
                <div className="row clearfix">
                  <div className="form-group col-md-4 col-sm-6 col-xs-12" data-aos="zoom-in">
                    <input
                      type="text"
                      name="username"
                      value={formData.username}
                      placeholder="Full Name"
                      required=""
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-xs-12" data-aos="zoom-in">
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      placeholder="Email"
                      required=""
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-xs-12" data-aos="zoom-in">
                    <input
                      type="text"
                      name="number"
                      value={formData.number}
                      placeholder="Number"
                      required=""
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12" data-aos="zoom-in">
                    <textarea
                      name="message"
                      placeholder="Write Message"
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group col-md-12 col-sm-12 col-xs-12" data-aos="zoom-in">
                    <div className="text-center">
                      <button type="submit" className="theme-btn btn-style-two">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
        </div>*/}
            {/*End Quote Form*/}
          </div>
        </div>
      </section>
      {/*Info Section*/}
      <section className="info-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="info-column col-md-4 col-sm-4 col-xs-12">
              {/*Info Box*/}
              <div className="info-box">
                <div className="icon-box" data-aos="zoom-in">
                  <span className="flaticon-phone-book" />
                </div>
                <ul>
                  <li data-aos="zoom-in">
                    <Link to="tel:+919977323535">+91 9977323535</Link> <br />
                    <Link to="mailto:info@assuremarketresearch.com">
                      info@assuremarketresearch.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="info-column col-md-4 col-sm-4 col-xs-12">
              {/*Info Box*/}
              <div className="info-box">
                <div className="icon-box">
                  <span className="flaticon-clock" data-aos="zoom-in" />
                </div>
                <ul data-aos="zoom-in">
                  <li>
                    Mon - Sat 9.00Am to 06.00PM <br />
                    Sunday Closed
                  </li>
                </ul>
              </div>
            </div>
            <div className="info-column col-md-4 col-sm-4 col-xs-12">
              {/*Info Box*/}
              <div className="info-box">
                <div className="icon-box" data-aos="zoom-in">
                  <span className="flaticon-placeholder-2" />
                </div>
                <ul data-aos="zoom-in">
                  <li>
                  <a
  href="https://www.google.com/maps/search/?api=1&query=HALL+NO+3,+PLOT+NO+10+B,+AMAN+COMPLEX,+GOVIND+GARDEN,+GOVVINDPURA,+RAISEN+ROAD,+BHOPAL,+462023,+MADHYA+PRADESH,+INDIA"
  target="_blank"

>
 Govvindpura, Raisen Road, Bhopal - 462023, Madhya Pradesh, India
</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Map Section*/}
      <section className="map-section">
        {/*Map Outer*/}
        <div className="map-outer">
          {/*Map Canvas*/}
          <div className="map-canvas">
            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235527.49482446615!2d75.6990383003202!3d22.723888290820824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa1468e90a640d625%3A0xbe8a7c086e0d278f!2sAssure%20Market%20Research!5e0!3m2!1sen!2sin!4v1699028964640!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              className="google-map"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </section>
    </>
  );
}
