import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200,
});
export default function Header() {
  const phoneNumber = "+91 9977323535";

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* Preloader */}
      {/* <div className="preloader" /> */}
      {/* Main Header*/}
      <header className="main-header header-style-one">
        <div className="header-upper">
          <div className="auto-container" data-aos="fade-down">
            <div className="clearfix">
              <div className="logo-box">
                <div className="logo">
                  <Link to="/">
                    <img src="./assets/images/Assurelogo.png" alt="" title="" />
                  </Link>
                </div>
              </div>
              <div className="upper-right">
                <div className="clearfix">
                  {/*Info Box*/}
                  <div className="upper-column info-box">
                    <div className="icon-box">
                      <span className="flaticon-phone-book" />
                    </div>
                    <ul>
                      <li>
                        <Link to="tel:+919977323535">+91 9977323535</Link>{" "}
                        <br />
                        <Link to="mailto:info@assuremarketresearch.com">
                          info@assuremarketresearch.com
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/*Info Box*/}
                  <div className="upper-column info-box">
                    <div className="icon-box">
                      <span className="flaticon-clock" />
                    </div>
                    <ul>
                      <li>
                        Mon - Sat 09.00AM to 06.00PM <br />
                        Sunday Closed
                      </li>
                    </ul>
                  </div>
                  {/*Info Box*/}
                  <div className="upper-column info-box">
                    <div className="icon-box">
                      <span className="flaticon-placeholder-2" />
                    </div>
                    <ul>
                      <li>
                        <a
                          href="https://www.google.com/maps/search/?api=1&query=HALL+NO+3,+PLOT+NO+10+B,+AMAN+COMPLEX,+GOVIND+GARDEN,+GOVVINDPURA,+RAISEN+ROAD,+BHOPAL,+462023,+MADHYA+PRADESH,+INDIA"
                          target="_blank"
                        >
                          Govvindpura, Raisen Road, Bhopal - 462023,(M.P)
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Header Upper*/}
        {/*Header Lower*/}
        <div className="header-lower">
          <div className="auto-container" data-aos="fade-down">
            <div className="nav-outer clearfix">
              {/* Main Menu */}
              <nav className="main-menu">
                <div className="navbar-header">
                  {/* Toggle Button */}
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                  >
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                <div className="navbar-collapse collapse clearfix">
                  <ul className="navigation clearfix">
                    <li className="current dropdown">
                      <Link
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        to="/"
                      >
                        Home
                      </Link>
                      {/* <ul>
                        <li>
                          <Link to="index-2.html">Home 01</Link>
                        </li>
                        <li>
                          <Link to="index-3.html">Home 02</Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/">Header Styles</Link>
                          <ul>
                            <li>
                              <Link to="index-2.html">Header Type 01</Link>
                            </li>
                            <li>
                              <Link to="index-3.html">Header Type 02</Link>
                            </li>
                          </ul>
                        </li>
                      </ul> */}
                    </li>
                    <li className="dropdown">
                      <Link
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        to="/Services"
                      >
                        Services
                      </Link>
                      {/* <ul>
                        <li>
                          <Link to="about.html">About us</Link>
                        </li>
                        <li>
                          <Link to="careers.html">Careers</Link>
                        </li>
                        <li>
                          <Link to="partners.html">Partners</Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="dropdown">
                      <Link
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        to="/About"
                      >
                        About us
                      </Link>
                      {/* <ul>
                        <li>
                          <Link to="services.html">Our Services</Link>
                        </li>
                        <li>
                          <Link to="financial-planning.html">
                            Financial Planning
                          </Link>
                        </li>
                        <li>
                          <Link to="savings-investment.html">
                            Savings &amp; Investment
                          </Link>
                        </li>
                        <li>
                          <Link to="private-banking.html">Private Banking</Link>
                        </li>
                        <li>
                          <Link to="consumer-leasing.html">Consumer Leasing</Link>
                        </li>
                        <li>
                          <Link to="strategic-planning.html">
                            Strategic Planning
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="dropdown">
                      <Link
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        to="/pricing"
                      >
                        Pricing
                      </Link>
                      {/* <ul>
                        <li>
                          <Link to="projects.html">Cases Type 01</Link>
                        </li>
                        <li>
                          <Link to="projects-2.html">Cases Type 02</Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="dropdown">
                      <Link
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        to="/payment"
                      >
                        Payment
                      </Link>
                      {/* <ul>
                        <li>
                          <Link to="careers.html">Careers</Link>
                        </li>
                        <li>
                          <Link to="testimonials.html">Testimonials</Link>
                        </li>
                        <li>
                          <Link to="partners.html">Partners</Link>
                        </li>
                        <li>
                          <Link to="faqs.html">FAQs</Link>
                        </li>
                        <li>
                          <Link to="coming-soon.html">Comming Soon</Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="dropdown">
                      <Link
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        to="/contact"
                      >
                        Contact
                      </Link>
                      {/* <ul>
                        <li>
                          <Link to="blog-list.html">Blog List View</Link>
                        </li>
                        <li>
                          <Link to="blog-grid.html">Blog Grid View</Link>
                        </li>
                        <li>
                          <Link to="blog-detail.html">Blog Single</Link>
                        </li>
                      </ul> */}
                    </li>
                    <li>
                      <Link
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        to="/Faq"
                      >
                        FAQ
                      </Link>
                    </li>
                
                  </ul>
                </div>
              </nav>
              {/* Main Menu End*/}
              <div className="options-box clearfix">
                {/*Language Box*/}
                {/* <div className="lang-box">
                    <div className="dropdown">
                      <button
                        className="lang-btn dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Eng <span className="fa fa-angle-down" />
                      </button>
                      <ul
                        className="dropdown-menu search-panel"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <Link to="/">English</Link>
                        </li>
                        <li>
                          <Link to="/">German</Link>
                        </li>
                        <li>
                          <Link to="/">French</Link>
                        </li>
                        <li>
                          <Link to="/">Dutch</Link>
                        </li>
                        <li>
                          <Link to="/">Chinese</Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
        {/*End Header Lower*/}
        {/*Sticky Header*/}
        <div className="sticky-header" data-aos="fade-down">
          <div className="auto-container clearfix">
            {/*Logo*/}
            <div className="logo pull-left">
              <Link to="/" className="img-responsive">
                <img src="images/logo-small.png" alt="" title="" />
              </Link>
            </div>
            {/*Right Col*/}
            <div className="right-col pull-right">
              {/* Main Menu */}
              <nav className="main-menu">
                <div className="navbar-header">
                  {/* Toggle Button */}
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                  >
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                <div className="navbar-collapse collapse clearfix">
                  <ul className="navigation clearfix">
                    <li className="current dropdown">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="/Services">Services</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="/About">About us</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="/payment">Payment</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/Faq">FAQ</Link>
                    </li>
                 
                  </ul>
                </div>
              </nav>
              {/* Main Menu End*/}
            </div>
          </div>
        </div>
        {/*End Sticky Header*/}
      </header>
      {/*End Main Header */}
      {/*Main Slider*/}

      {/*End pagewrapper*/}
      {/*Scroll to top*/}
      <div
        className="scroll-to-top scroll-to-target"
        data-aos="fade-down"
        onClick={() => scrollToTop()}
        data-target="/"
      >
        <span className="icon fa fa-angle-double-up" />
      </div>
      {/* Color Palate / Color Switcher */}
      <div className="color-palate">
        <Link to={`tel:${phoneNumber}`} className="color-trigger">
          <i className="fas fa-phone-alt"></i>
        </Link>
      </div>
      {/* /.End Of Color Palate */}
      {/*Revolution Slider*/}
      {/*Color Switcher*/}
    </>
  );
}
