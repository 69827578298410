import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import "aos/dist/aos.css";
import AOS from "aos";
AOS.init({
  duration: 1200
});
export default function Faq() {
  const phoneNumber = "+91 9977323535";
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/6.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>FAQ</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active"> FAQ</li>
            </ul>
          </div>
        </div>
      </section>

  

        {/*Statistics Section*/}
        <section className="statistics-section">
            <div className="outer-container clearfix">
              {/*Image Column*/}
              <div className="image-column">
                <div
                  className="image-layer"
                  style={{
                    backgroundImage: "url(assets/images/background/1.jpg)",
                  }}
                />
              </div>
              {/*Content Column*/}
              <div
                className="content-column"
                style={{
                  backgroundImage: "url(assets/images/background/3.jpg)",
                }}
              >
                <div className="inner">
                  {/*Heading*/}
                  <div className="sec-title light">
                    <h1 data-aos="zoom-in">Our Infrastructure</h1>
                    <div className="text" data-aos="zoom-in">
                      This product is meant for educational purposes only.
                      Resemblance We boast a cutting-edge infrastructure,
                      equipped with the latest hardware and software, ensuring
                      optimal performance. Our dedicated research team operates
                      during live market hours, vigilantly monitoring market
                      activities and significant news events. This proactive
                      approach not only safeguards against substantial losses
                      but also preserves clients’ capital. Our advanced
                      infrastructure empowers us to deliver unparalleled
                      services tailored to meet diverse requirements.
                    </div>
                  </div>
          
                </div>
              </div>
            </div>
          </section>
    </>
  );
}
