import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200,
});

export default function RefundPolicy() {
  const phoneNumber = "+91 9977323535";

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Refund Policy</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                {" "}
                Refund Policy
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="about-two hire mx-2">
        <div class="outer-container clearfix">
          <div class="image-column">
            <div class="image-layer"></div>
          </div>
        </div>
      </section>

      <div className="container mb-3 ">
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Privacy Policy
        </h1>
        <div className="">
          <p>
            The parties agreed that the first party does not have an established
            refund policy for its services.  A subscription is final once it has
            been made, and no refunds will be given for payments.  
          </p>
        </div>

        <h1 style={{ fontWeight: 500 }}>
          {" "}
          Checklist before subscribing to our services:
        </h1>
        <div className="refund">
        <ul style={{ listStyleType: 'circle!important' }}>
            <li>
              It is advisable to check all the information about our services
              and risks involved in trading. We don t offer any kind of
              promissory or guaranteed returns.
            </li>
            <li>
              In any case, due to any personal reason if any client cannot
              continue services after making the payment, then we can provide
              services in the future 
            </li>
            <li>
              Before making payment you can clear all your doubts by speaking
              with our executive.
            </li>
            <li>
              You can read all information about our packages, and services
              before subscription and it is advisable from our side to check the
              refund policy, privacy policy, complaints, and disclaimer before
              subscription.
            </li>
            <li>
              For us, our clients are always on top priority so we don't
              compromise with the support but about results in our research
              recommendations, we don't give any guarantee. Market movements are
              not in our hands so never expect consistently profitable results
              from our services and prepare yourself for losses also.
            </li>
            <li>
              Before opting for our services, we strongly encourage you to
              familiarize yourself with the risks involved in trading or
              investing in the market. If you are totally new in the market,
              check research recommendations for a few days so that you can
              avail proper knowledge and information on stocks and market
              volatility.
            </li>
            <li>
              Make sure that you do not give your credit card details to any
              third party who could access your account. Dont share your
              credentials with anyone in any form. This way we will make sure
              that your membership is safe.
            </li>{" "}
            <li>
              To enjoy uninterrupted services, customers are responsible for the
              timely payment of the renewal fee
            </li>
            <li>
              By making payment for our services, it is implied that you have
              read and agreed to all of the terms and conditions, disclaimers,
              Disclosure, privacy policy, refund, and cancellation policies.
            </li>{" "}
          </ul>
        </div>
      </div>
    </>
  );
}
