import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200,
});
export default function Disclaimer() {
  const phoneNumber = "+91 9977323535";

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Disclaimer </h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                {" "}
                Disclaimer
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="about-two hire mx-2">
        <div class="outer-container clearfix">
          <div class="image-column">
            <div class="image-layer"></div>
          </div>
        </div>
      </section>
      <div className="container ">
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Disclaimer
        </h1>
        <div className="">
          <p>
            ASSURE MARKET RESEARCH is SEBI registered Research Analyst in terms
            of SEBI (Research Analyst) Regulations, 2014. SEBI Research Analyst
            No: INH000011802 or its associates has not been debarred/ suspended
            by SEBI or any other regulatory authority for accessing /dealing in
            the securities Market.
          </p>
          <p className="my-1">
            The information and views in the reports, our website & all the
            services (“Research Information”)we provide are believed to be
            reliable, but we do not represent or warrant the accuracy,
            completeness or reliability of the information contained in our
            Research Information, investors and clients are advised to
            independently evaluate the market conditions/ risks involved, before
            making any trading/investment decisions. The Research Information is
            not intended to be an exhaustive statement on the financial
            instruments, issuers, markets or developments referred to therein.
            Reasonable care has been taken to ensure that the Research
            Information are not misleading or untrue at the time of publication.
            Any opinions expressed in the Research Information are subject to
            change without notice. The analysis contained in the Research
            Information is based on numerous assumptions. Different assumptions
            could result in materially different results. Information in the
            specific research reports are for the private use of the person to
            whom it has been provided without any liability whatsoever on the
            part of the Firm, its partners, employees, and associated entities.
            The research material published on this website does not constitute
            an offer or solicitation to buy or sell any securities referred to
            therein. It should not be so construed, nor should it or any part of
            it form the basis of, or be relied on in connection with, any
            contract or commitment whatsoever.
          </p>
          <p className="my-1">
            Save as otherwise disclosed on this page, and Disclaimers and
            disclosures attached to each piece of research report, the Firm, its
            employees and associate entities of the Firm are not aware of any
            relationships or circumstances relating to it, which could
            reasonably be expected to impair the objectivity of the Research
            Information. Research reports issued to individual clients under
            specific instruction is for the sole benefit of that client, and the
            report shall not be shared with any other person without the consent
            of the Firm..
          </p>
          <p className="my-1">
             Users of our website have the right to choose the product/s that
            suits them the most. The information contained on our website is
            from publicly available data or other sources believed to be
            reliable. Assure market research reports only provide information,
            updates, and analysis.
          </p>
          <p className="my-1">
            The Firm, its employees and associated entities shall not be in any
            way responsible for any loss or damage that may arise to any person
            from any inadvertent error in the information contained in the
            research reports. The documents are provided for assistance only and
            are not intended to be and must not alone be taken as the basis for
            an investment decision. The information herein, together with all
            estimates and forecasts, can change without notice. Our Clients
            (Paid or Unpaid), any third party or anyone else has no rights to
            forward or share our calls or SMS or Report or any information
            provided by us to/with anyone which is received directly or
            indirectly by them. If found so then Serious Legal Actions can be
            taken.
          </p>
          <p className="my-1">
            All information on assuremarketresearch.com, research reports or
            other services is solely for the personal information of the
            recipient, and must not be singularly used as the basis of any
            trading or investment decision. Free research posted in this website
            is for educational purposes only.
          </p>
          <p className="my-1">
            {" "}
            The Firm, its affiliates, and employees may, from time to time, make
            investments. The views contained on the website, research reports,
            and advisory are those of the analyst, and the Subject Company may
            or may not subscribe to all the views expressed within.
            Research-based on technical and derivative analysis center on
            studying charts of a stock s price movement, outstanding positions
            and trading volume, as opposed to focusing on a company s
            fundamentals and, as such, may not match with a report on a company
            s fundamentals. The research published on the website or in research
            reports has been done on the basis of publicly available
            information, internal data and other reliable sources believed to be
            true, but we do not represent that it is accurate or complete and it
            should not be relied on as such, as it is for general guidance only.
            The Firm endeavors to update on a reasonable basis the information
            discussed in this material, there may be regulatory, compliance, or
            other reasons that prevent us from doing so.
          </p>
        </div>
      </div>
    </>
  );
}
