import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200,
});

export default function TermsandConditions() {
  const phoneNumber = "+91 9977323535";

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Terms and Conditions</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                {" "}
                Terms and Conditions
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="about-two hire mx-2">
        <div class="outer-container clearfix">
          <div class="image-column">
            <div class="image-layer"></div>
          </div>
        </div>
      </section>

      <div className="container mb-3 ">
        <h1 style={{ fontWeight: 500 }}> Terms and Conditions</h1>
        <div className="refund">
          <ul style={{ listStyleType: "circle!important" }}>
            <li>
              1) Payment to be made in advance for any mode of subscription and
              renewal of subscription, Training & Education
            </li>
            <li>
              2) Payment will be refunded as per the Refund Policy mentioned on
              Website.
            </li>
            <li>
              3)All reports will be available by email or on the website after
              the secured client login
            </li>
            <li>
              4) All Recommendation will be given through SMS or on Instant
              messenger.
            </li>
            <li>
              5) If opted for any training the access to the videos would be for
              a limited period only.
            </li>
            <li>
              6)By joining or registering in the mailing of assure market
              research or by filling any form, you have chosen to receive free
              updates via email, SMS, or any other modes of communication and
              will abide by all the terms mentioned herewith. These terms may be
              subject to change from time to time and you are bound by such
              changes.
            </li>
            <li>
              7)By joining the mailing assuremarketresearch.com or subscribing
              to any of our paid services you have chosen to receive via, email,
              SMS, or other modes of communication, research-based updates in
              text video or webinar formats or new technology formats that may
              be available from time to time.
            </li>{" "}
            <li>
              8) Membership/Subscription means paid access to the website/blog
              and its updates posted on assuremarketresearch.com. It neither
              guarantees a quantity or number of updates nor timeliness or a
              fixed time of update. It is however the endeavor of Assure market
              research to maintain the highest level of accuracy timeliness and
              quality in its work.
            </li>
            <li>
              9) Any reference made to “Assuremarketresearch.com” is a reference
              to the Firm concerned. Assure market Research and
              Assuremarketresearch.com are synonymous with each other
            </li>
            <li>
              10) All content published on assuremarketresearch.com is the
              property of the Firm and copyright. Redistribution, republication,
              plagiarism, without prior approval from Assure Market Research and
              without proper credit is subject to legal action under Indian
              copyright law
            </li>
            <li>
              11) Certain portions of the website have restricted access meant
              for subscribers only. Restricted access rules may change from time
              to time and it s the sole discretion of Assure Market research as
              to which portions of the website will be restricted to members and
              which will be public. A prior intimation of such changes will not
              be given.
            </li>
            <li>
              12) If Assure Market Research provides you with a user ID and
              password to enable you to access restricted areas of this website
              or other content or services, you must ensure that the user ID and
              password are kept confidential. The Firm may disable your user ID
              and password at its sole discretion without notice or explanation.
            </li>
          </ul>
        </div>

        <h1 style={{ fontWeight: 500 }}>
          {" "}
          PLEASE READ THE FOLLOWING “TERMS OF SERVICE” AGREEMENT CAREFULLY
          BEFORE SUBSCRIBING
        </h1>
        <div className="">
          <p>
            Assure Market Research provides services subject to your compliance
            with the terms and conditions set forth in this Agreement. By
            subscribing to these services, you agree to be bound by these terms
            and conditions.
          </p>
          <br />
          <p>
            This Agreement is made between us and you. We reserve the right at
            any time to change the terms and conditions of this Agreement,
            change the services, including eliminating or discontinuing any
            content on or feature of the service or change any fees or charges
            for the service.
          </p>
          <br />
          <p>
            Be sure to review this Agreement periodically to ensure familiarity
            with the most current version. Our policy with respect to the
            collection and use of your personal information is set forth in our
            Privacy Policy.
          </p>
        </div>

        <h1 style={{ fontWeight: 500 }}> Code of Conduct</h1>
        <div className="">
          <p>While using any of the Services you agree not to:</p>
          <br />
          <p>
            Restrict or inhibit any other visitor or subscriber from using the
            Service, including, without limitation, by means of “hacking” or
            “cracking” or defacing any portion of any of the Service or Website
          </p>
          <br />
          <p>Use the Website for any unlawful purpose</p>
          <br />
          <p>
            Express or imply that any statements you make are endorsed by us,
            without our prior written consent.
          </p>
          <br />
          <p>
            Transmit any content or information comprising the Service or
            Website, which is unlawful, fraudulent, threatening, harassing,
            abusive, libelous, defamatory, obscene or otherwise objectionable,
            or infringes on our or any third party s intellectual property or
            other rights
          </p>
          <br />
          <p>Engage in spamming or flooding</p>
          <br />
          <p>
            Transmit any software or other materials that contain any virus,
            worm, time bomb, Trojan horse, or other harmful or disruptive
            components
          </p>
          <br />
          <p>
            Modify, adapt, sublicense, translate, sell, reverse engineer,
            decompile or disassemble any portion of the Service or Website
          </p>
          <br />
          <p>
            Remove any copyright, trademark, or other proprietary rights notices
            contained in the Service or Website
          </p>
          <br />
          <p>
            “Frame” or “mirror” any part of the Service or Website without our
            prior written authorization
          </p>
          <br />
          <p>
            Use any robot, spider, site search/retrieval application, or other
            manual or automatic device or process to retrieve, index, “data
            mine,” or in any way reproduce or circumvent the navigational
            structure or presentation of the Service, Website or its contents
          </p>
          <br />
          <p>
            Harvest or collect information about Site visitors or members
            without their / our express consent{" "}
          </p>
          <br />
          or
          <br />
          <p>
            Permit anyone else whose account or subscription was terminated to
            use the Service or Website through your subscription, username or
            password.
          </p>
          <br />
          <p>
            While using the Site you agree to comply with all applicable laws,
            rules, and regulations.
          </p>
          <br />
          <p>
            If you are our subscriber, and you have reason to believe that your
            online account is no longer secure, you must promptly change your
            password by updating your account information and immediately notify
            us by emailing us at:
          </p>
        </div>
        <h1 style={{ fontWeight: 500 }}>Termination</h1>
        <div className="">
          <p>
            This Agreement shall remain effective until terminated in accordance
            with its terms. We may terminate this Agreement, and/or your access
            to and use of the Site or any portion thereof, immediately, in the
            event we determine, in our sole discretion, that you have breached
            this Agreement. In addition, we reserve the right to terminate this
            agreement without cause.
          </p>
        </div>
        <h1 style={{ fontWeight: 500 }}>Services and Tools</h1>
        <div className="">
          <p>
            Your use of certain services / Websites may be governed by
            additional rules, which are available on the Site or by hyperlinks
            from other sites, in connection with the Service. By using any
            Service you are acknowledging that you have reviewed all
            corresponding rules and agree to be bound by them. Some of the
            Services may have been provided by third parties for your use. You
            expressly acknowledge and agree that your use of all services is
            solely at your risk.
          </p>
        </div>
        <h1 style={{ fontWeight: 500 }}>Ownership and Restrictions on Use</h1>
        <div className="">
          <p>
            The copyright to the services / Website is owned by Trade Aura
            Research. You may only access and use the material, and download
            and/or print out only one copy of any material, solely for your
            personal use. You may not republish, upload, post, transmit or
            distribute material in any way without our prior written permission.
            Modification of the material or use of the material for any other
            purpose is a violation of our copyright and other proprietary rights
            and is strictly prohibited. You acknowledge that you do not acquire
            any ownership rights by using the Site.
          </p>
        </div>
        <h1 style={{ fontWeight: 500 }}>Links to Other Websites</h1>
        <div className="">
          <p>
            The Site may contain links to other Internet websites or resources.
            We neither control nor endorse such other websites, nor have we
            reviewed or approved any content that appears on such other
            websites. You acknowledge and agree that we shall not be held
            responsible for the legality, accuracy, or inappropriate nature of
            any content, advertising, products, services, or information located
            on or through any other websites, nor for any loss or damages caused
            or alleged to have been caused by the use of or reliance on any such
            content.
          </p>
        </div>
        <h1 style={{ fontWeight: 500 }}>DISCLAIMERS</h1>
        <div className="">
          <p>
            The Website, the material contained in any service, and anything
            that is obtained or accessed through the Website are provided “as
            is” and without representations or warranties of any kind, either
            expressed or implied, to the fullest extent permissible pursuant to
            applicable law. Assure Market research, its officers, employees,
            affiliates, suppliers, advertisers, and agents disclaim all
            warranties, express, implied or statutory, including, but not
            limited to, implied warranties of title, non-infringement,
            merchantability, and fitness for a particular purpose, and all
            warranties relating to the adequacy, accuracy or completeness of any
            information contained in the Service / Website.
            Assuremarketresearch.com, and its affiliates, suppliers, agents, and
            sponsors do not warrant that your use of any Service / Website will
            be uninterrupted, error-free, or secure, that defects will be
            corrected, or that the Site or the server(s) on which the Site is
            hosted are free of viruses or other harmful components. You
            acknowledge that you are responsible for obtaining and maintaining
            all telephone, computer hardware, and other equipment needed to
            access and use the Site, and all charges related thereto. You assume
            total responsibility and risk for your use of the Service / Website
            and your reliance thereon. No opinion, advice, or statement of
            Assure market Research, or its affiliates, suppliers, agents,
            members, or visitors, whether made on the Site or otherwise, shall
            create any warranty. Your use of the Services / Website and any
            materials provided is entirely at your own risk.
          </p>
          <br />
          <p>
            A possibility exists that our Service / Website could include
            inaccuracies or errors. Additionally, a possibility exists that
            unauthorized alterations could be made by third parties to the
            Service / Website. Although we attempt to ensure the integrity of
            the Service / Website, we make no guarantees as to their
            completeness or correctness. In the event that such a situation
            arises, please contact us at: info@assuremarketresearch.com, if
            possible, with a description of the material to be checked and the
            location where such material can be found, as well as information
            sufficient to enable us to contact you. We will try to address your
            concerns as soon as reasonably practicable.
          </p>
        </div>
        <h1 style={{ fontWeight: 500 }}>Limitation of Liability</h1>
        <div className="">
          <p>
            Neither Assure market research nor its affiliates, suppliers,
            advertisers, affiliates, or agents or sponsors are responsible or
            liable for any indirect, incidental, consequential, special,
            exemplary, punitive or other damages under any contract, for any
            negligence, or other damage arising out of or relating in any way to
            the Service or Website and/or content contained, or any product or
            service purchased through the Site. Your sole remedy for
            dissatisfaction with the Service / Website and/or content contained
            within it is to stop using the Service / Website. The only and
            maximum liability for all damages, losses, and causes of action
            shall be the total amount paid by you, if any, to access the Service
            / Website.
          </p>
        </div>
        <h1 style={{ fontWeight: 500 }}>Indemnification</h1>
        <div className="">
          <p>
            You agree to indemnify, defend and hold us, our officers, employees,
            agents, and representatives harmless from and against any and all
            claims, damages, losses, costs (including reasonable attorneys
            fees), or other expenses that arise directly or indirectly out of or
            from (a) your breach of this Agreement, including any violation of
            the Code of Conduct above; (b) any allegation that any materials
            that you submit to us or transmit to the Website, infringe or
            otherwise violate the copyright, trademark, trade secret or other
            intellectual property or other rights of any third party; and/or (c)
            your activities in connection with the Service / Website. 
          </p>
          <br />
          <p>
            Readers are advised that the material/suggestion contained in any
            Service / Website is given solely for information purposes and
            should not to be construed as an offer to sell or the solicitation
            of an offer to buy any security. The opinions and analyses included
            herein are based on sources believed to be reliable and written in
            good faith, but no representation or warranty, expressed or implied
            is made as to their accuracy, completeness or correctness.
          </p>
        </div>

        <h1 style={{ fontWeight: 500 }}>
          YOU SHOULD VERIFY ALL CLAIMS AND DO YOUR OWN RESEARCH BEFORE INVESTING
          IN ANY SECURITIES MENTIONED IN ANY SERVICE / WEBSITE. INVESTING IN
          SECURITIES IS SPECULATIVE AND CARRIES A HIGH DEGREE OF RISK. YOU MAY
          LOSE PART OR ALL OF YOUR PRINCIPAL INVESTMENT.
        </h1>
        <div className="">
          <p>
             Users are urged to consult with their own independent financial
            advisors with respect to any investment. All information contained
            in the Service / Website should be independently verified with the
            companies mentioned. Neither Assure Market Research nor its
            officers, contributors or employees/consultants accept any liability
            whatsoever for any direct or consequential loss arising from any use
            of information provided in any Service/ Website. Assure Market
            Research, and its officers, contributors or employees/consultants
            may on occasion hold positions in the securities mentioned in
            reports. These positions may change at anytime.
          </p>
          <br />
          <p>
            Please note that Assure market Research does not receive
            compensation of any kind and will never take any compensation from
            any company that is mentioned in its Service / Website.
          </p>
        </div>
        <h1 style={{ fontWeight: 500 }}>
          USE OF OUR SERVICES / WEBSITE SIGNIFIES YOUR ACCEPTANCE OF THIS TERMS
          OF SERVICE AGREEMENT
        </h1>
      </div>
    </>
  );
}
