import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200,
});
export default function Warning() {
  const phoneNumber = "+91 9977323535";

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        />
        <div className="auto-container" data-aos="zoom-in">
          <h1>Warning & Disclaimer </h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in">
                {" "}
                Warning & Disclaimer
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="about-two hire mx-2">
        <div class="outer-container clearfix">
          <div class="image-column">
            <div class="image-layer"></div>
          </div>
        </div>
      </section>
      <div className="container ">
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Warning
        </h1>
        <div className="">
          <p>
            “Investment in securities market are subject to market risks. Read
            all the related documents carefully before investing.”
          </p>
        </div>
        <h1 className="fs-1" style={{ fontWeight: 500 }}>
          {" "}
          Disclaimer
        </h1>
        <div className="">
          <p>
            “Registration granted by SEBI and certification from NISM in no way
            guarantee performance of the intermediary or provide any assurance
            of returns to investors”
          </p>
        </div>
      </div>
    </>
  );
}
