import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
export default function Payment() {
  const phoneNumber = "+91 9977323535";
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
      <section className="page-title">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(assets/images/background/6.jpg)" }}
        />
        <div className="auto-container">
          <h1 data-aos="zoom-in">Payment</h1>
          <div className="bread-crumb">
            <ul className="clearfix" data-aos="zoom-in">
              <li>
                <Link to="/" data-aos="zoom-in">Home</Link>
              </li>
              <li className="active" data-aos="zoom-in"> Payment</li>
            </ul>
          </div>
        </div>
      </section>
      <section class="partners-section">
        <div class="auto-container">
          <div class="sec-title centered paymentcard" data-aos="zoom-in">
            <h1>Payment Modes Are :-</h1>
            <div class="text" data-aos="zoom-in">
              1. Select the desired package. Find the Packages at OUR PACKAGES.
            </div>
            <div class="text" data-aos="zoom-in">
              2. Make payment by Online transfer ATM Transfer.
            </div>
            <div class="text">3. Make payment by Cheque </div>
          </div>
        </div>
      </section>

      <section class="why-we">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="text-column pull-right col-md-6 col-sm-12 col-xs-12">
              <div class="inner">
                <h2 data-aos="zoom-in">Account Details</h2>
                <div class="text" data-aos="zoom-in">  <strong>Account Name:</strong>  Assure market research</div>
                <div class="text" data-aos="zoom-in">  <strong> Account No.</strong>50200095896412</div>
                <div class="text" data-aos="zoom-in">
                  
                <strong> IFSC: </strong>
                HDFC0009020</div>
                <div class="text">
                  <p data-aos="zoom-in" > 
                    <strong>Bank Name:</strong> HDFC Bank
                  </p>
                  <p data-aos="zoom-in">
                    <strong>Branch:</strong> Raisen Road, Bhopal
                  </p>
                </div>
              </div>
            </div> 

            <div class="image-column col-md-6 col-sm-8 col-xs-12">
              <div class="inner">
                <figure class="image">
                  <img src="assets/images/QRCode.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
